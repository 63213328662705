import React from 'react' 
import {
    Hero,
    HeroInfoCcontainer,
    H1,
    P,
    PrimaryButton,
    PrimaryButtonLarge,
    SecondaryButton,
    ButtonsContainer,
    Img
} from './Styles'
import { Link, animateScroll as scroll } from "react-scroll";

export const HeroComponent = ({ data, backgroundColor, setModalIsOpen, us }) => {
    console.log(data)

    const heroInfoContent = () => {



        return (
            <HeroInfoCcontainer>
            <H1>{data.title1}</H1>
            <H1>{data.title2}</H1>
            <H1>{data.title3}</H1>
            <P>{data.text}</P>
            <ButtonsContainer>
                {
                    data.buttons.length > 0 ?
                        data.buttons.map(button => {
                            if(button.type === "primary"){
                                console.log(button)
                                return(
                                    <PrimaryButton onClick={()=> setModalIsOpen(true)}>{button.text}</PrimaryButton>
                                    )
                                }else{
                                    if(us){
                                        return(
                                            <Link
                                                activeClass="active"
                                                to="startup"
                                                spy={true}
                                                smooth={true}
                                                offset={-70}
                                                duration={500}
                                            >
                                                <SecondaryButton>{button.text}</SecondaryButton>
                                            </Link>
                                        )
                                    }else {
                                        return(
                                            <Link
                                                activeClass="active"
                                                to="confidence"
                                                spy={true}
                                                smooth={true}
                                                offset={-70}
                                                duration={500}
                                            >
                                                <SecondaryButton>{button.text}</SecondaryButton>
                                            </Link>
                                        )
                                    }
                                }
                        })
                    :
                    <>
                        {
                            data.buttons[0].type === 'primary' ? 
                            <PrimaryButtonLarge onClick={()=> setModalIsOpen(true)}>{data.button.text}</PrimaryButtonLarge> :
                            <SecondaryButton>{data.button.text}</SecondaryButton>
                        }
                    </>
                }
            </ButtonsContainer>
        </HeroInfoCcontainer>
        )
    }

    return (
        <>
        {
            window.screen.width > 480 ?
                <Hero image={data.heroImg} backgroundColor={backgroundColor}>
                    {heroInfoContent()}
                </Hero>
                :
                <>
                    <Img src={data.heroImg} />
                    {heroInfoContent()}
                </>
        }
        </>
    )
}