import React from 'react'  
import {
    Container,
    ContainerImage,
    Arrow,
    Image
} from './Styles'
import ArrowImage from '../../assets/arrow.svg'


export const Confidence = ({ img, background }) => {
    return (
        <Container background={background} >
            {/* {window.screen.width < 768 && <Arrow style={{transform: 'rotate(180deg)'}} src={ArrowImage}/>} */}
                <ContainerImage>
                    <Image src={img} />
                </ContainerImage>
            {/* {window.screen.width < 768 && <Arrow src={ArrowImage}/>} */}
        </Container>
    )
}