import React, {useState, useEffect, useCallback} from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../../assets/components/logo.svg'
import Logo_res from '../../assets/components/3mit_res.svg'
import Menu from '../../assets/components/menu.svg'
import { MenuComponent } from '../../components/menu/Menu'
import './modal.css'
import ReactGA from 'react-ga';

import { 
    Header, 
    Navigation, 
    LogoWrapper, 
    LinksWrapper, 
    ContactWrapper,
    HeaderContent,
    NavigationContact
 } from './Styles'

export const HeaderComponent = ({ modalIsOpen }) => {

    const [y, setY] = useState(window.scrollY);
    const [isOpen, setIsOpen] = useState(false);

    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            // console.log(window.scrollY)
            setY(window.scrollY);
        }, [y]
      );

    useEffect(() => {
        window.addEventListener("scroll", (e) => handleNavigation(e));
        setY(window.scrollY);
      }, [y]);
    //   const [modalIsOpen, setModalIsOpen] = useState(false);
      
    const handleClick = () => {
        ReactGA.event({
            category: 'Contacto',
            action: 'boton de contacto',
            label: 'El usuario presiono el boton que redirige al whatsapp'
          });
    }

    return (
        <>
        <MenuComponent isOpen={isOpen} setIsOpen={setIsOpen} />
        {
            !modalIsOpen &&
            <Header background={ y > 250 ? true : false }>
                <HeaderContent>
                    <LogoWrapper>
                        <NavLink to="/"><img src={window.screen.width > 768 ? Logo : Logo_res } /></NavLink>
                    </LogoWrapper>
                    {
                        window.screen.width < 480 &&
                        <img src={Menu}  onClick={()=> setIsOpen(true)} />
                    }
                    <LinksWrapper>
                        <Navigation to="/odoo" className="navlink" activeClassName="selected">Odoo</Navigation>
                        <Navigation to="/desarrollo" className="navlink" activeClassName="selected">Desarrollo</Navigation>
                        <Navigation to="/nosotros" className="navlink" activeClassName="selected">Nosotros</Navigation>
                    </LinksWrapper>
                    <ContactWrapper>
                        {/* <NavigationContact onClick={()=> setModalIsOpen(true)} >Contactanos</NavigationContact> */}
                        <NavigationContact target="_blank" href={`https://wa.me/${process.env.REACT_APP_WS_3MIT}`} onClick={handleClick} >Contáctanos</NavigationContact>
                    </ContactWrapper>
                </HeaderContent>
            </Header>
        }
        </>
    )
}