import React, {useState, useEffect} from 'react' 
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {
    H2,
    colors,
} from '../../styles/Global'
import { Card } from './Card'
import styled from 'styled-components'

export const Carousel = ({ data, title, background }) => {


    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 2 },
    };

    const asd = '3MIT no solo nos apoyó con la implementación de Odoo, también crearon dento del software, un módulo completamente personalizado para nuestro proyecto de delivery a escala con una economía descentralizada entre comercios aliados, despachadores y usuarios en Venezuela.'

    const [items, setItems] = useState()
    useEffect(()=>{
        console.log(data)
        console.log(asd.length)
        const cards = data.map(card => {
            return <Card data={card} />
        })
        setItems(cards)
    },[])


    return (
        <Div background={background}>
            <H2 style={{marginBottom: '50px'}}>{title}</H2>
            <AliceCarousel
                mouseTracking
                items={items}
                responsive={responsive}
                // controlsStrategy="alternate"
            />
        </Div>
    )
}

const Div = styled.div`
    padding: 50px 0;
    width: 80%;
    margin: 50px auto;
    border-radius: 16px;
    background-color: ${props => props.background ? props.background : 'transparent' };
`