import { data } from 'browserslist'
import React from 'react' 
import {
    CardContainer,
    PersonContainer,
    PersonInfo,
    Name,
    Job,
    Testimony
} from './Styles'

export const Card = ({data}) => {
    return (
        <CardContainer>
            <PersonContainer>
                {/* <img src={data.img}/> */}
                <PersonInfo>
                    <Name>{data.name}</Name>
                    <Job>{data.job}</Job>
                </PersonInfo>
            </PersonContainer>
            <Testimony>{data.testimony}</Testimony>
        </CardContainer>
    )
}