import React from 'react'
import {
    CardContainer,
    CardImageContainer,
    CardImage,
    Title,
    Text,
    KnowMore
} from './Styles'

export const Card = ({ data }) => {
    return (
        <CardContainer>
            <CardImageContainer background={data.background}>
                <CardImage src={data.img} />
            </CardImageContainer>
            <Title>{data.title}</Title>
            <Text>{data.text}</Text>
            {data.knowMore && <KnowMore to={data.odoo ? "odoo" : "desarrollo"}>Conoce más</KnowMore>}
        </CardContainer>
    )
}