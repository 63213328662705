import styled from 'styled-components'
import { colors, size } from '../../styles/Global'

export const SectionContainer = styled.div`
    background-color: ${props => props.background ? props.background : 'white'};
    width: 80%;
    margin: 100px auto;
    padding: 20px 50px 100px;
    border-radius: 16px;
    @media (max-width: ${size.mobileM}) {
        width: 90%;
        padding: 20px 0;
    }
`
export const SectionTitle = styled.p`
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: ${colors.purpleText};
`
export const Title = styled.p`
    /* text-align: center; */
    font-size: 24px;
    font-weight: bold;
    color: ${colors.purpleText};
`
export const CardsContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

`
export const CardContainer = styled.div`
    width: ${props => props.little ? '180px' : '300px'};
    /* height: 310px; */
    height: ${props => props.little ? '245px' : '310px'};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    @media (max-width: ${size.mobileM}) {
        margin: ${props => props.noMargin ? '0 auto' : '80px auto'};
        box-sizing: border-box;
    }
`
export const CardImage = styled.img`
margin-bottom: 20px;
`
export const CardText = styled.p`
    /* text-align: center; */
    font-size: 16px;
    font-weight: ${props => props.bold ? 'bold' : 'normal'};;
    color: ${colors.purpleText};
`
export const Button = styled.img`
/* margin-bottom: 20px; */
`