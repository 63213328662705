import React, { useState, useEffect } from 'react' 
import HeroImg from '../../assets/odoo/hero.svg'
import HeroImgRes from '../../assets/odoo/hero_res.svg'
import OdooGuy from '../../assets/odoo/odooGuy.svg'
import Modal from 'react-modal';
import { Contact } from '../../components/header/Contact'
import { SectionWithText } from '../../components/sectionWithText/SectionWithText'
import { colors, H2 } from '../../styles/Global'
// header 
import { HeaderComponent } from '../../components/header/Header'
// hero 
import { HeroComponent } from '../../components/hero/Hero'
// seccion 
import { Section } from '../../components/section/Section'
import Comercial from '../../assets/odoo/comercial.svg'
import Marketing from '../../assets/odoo/marketing.svg'
import Recursos from '../../assets/odoo/logistica.svg'
import Logistica from '../../assets/odoo/recursos.svg'
// seccion 2
import Integraciones from '../../assets/odoo/integraciones.svg'
import Dev from '../../assets/odoo/dev.svg'
import Fuerza from '../../assets/odoo/fuerza.svg'
// seccion 3
import Venezuela from '../../assets/odoo/venecozuela.svg'
import Figuras from '../../assets/odoo/figuras.svg'
// seccion 4 
import { SectionWithButton } from '../../components/sectionWithButton/SectionWithButton'
import OdooAmigos from '../../assets/odoo/odooAmigos.svg'
// seccion 5
import { Confidence } from '../../components/confidence/Confidence'
// Carousel 
import { Carousel } from '../../components/carousel/Carousel'
import Testimony1 from '../../assets/testimonies/testimony1.svg'
import Testimony2 from '../../assets/testimonies/testimony2.svg'
// footer 
import { Footer } from '../../components/footer/Footer'

import Businesses from '../../assets/landing/confidence.svg'
import ReactGA from 'react-ga';



export const Odoo = () => {

    useEffect(() => {
        ReactGA.pageview('/odoo');
      }, []);

    const dev = false
    const [modalIsOpen ,setModalIsOpen] = useState(false)
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };
    const [hero, setHero] = useState({
        heroImg: window.screen.width > 480 ? HeroImg : HeroImgRes,
        title1: 'Invierte en Odoo y',
        title2: 'garantiza el crecimiento',
        title3: 'de tu negocio',
        text: 'Ahorra tiempo y dinero en el camino',
        buttons: [
            {
                type: 'primary',
                text: 'Solicita tu demo gratis'
            }
        ]
    })
    const [sectionWithText, setSectionWithText] = useState({
        img: OdooGuy,
        title: 'Conoce Odoo, el software que crece contigo',
        text: 'Odoo es un sistema empresarial integrado que se adapta con facilidad a los requerimientos de tu negocio. Su arquitectura no solo está diseñada para escalar según tú lo vayas necesitando, sino también para mantener tu negocio y hacerlo cada vez más robusto. ¡Lo complicado nunca fue tan sencillo!',
    })
    const [sectionOne, setSectionOne] = useState([
        {
            img: Comercial,
            text: 'Comercial y Finanzas',
            little: true
        },
        {
            img: Marketing,
            text: 'Marketing y Web',
            little: true
        },
        {
            img: Logistica,
            text: 'Logística, Manufactura y Servicios',
            little: true
        },
        {
            img: Recursos,
            text: 'Recursos Humanos',
            little: true
        }
    ]) 
    const [sectionTwo, setSectionTwo] = useState([
        {
            title: 'El fin de las integraciones problemáticas',
            img: Integraciones,
            text: 'Las aplicaciones de Odoo están perfectamente integradas entre sí, lo que te permite automatizar tus procesos comerciales operando cada área de la empresa en un mismo lugar.'
        },
        {
            title: 'Podemos desarrollar sobre Odoo',
            img: Dev,
            text: 'Odoo es un software de código abierto, lo que nos permite como desarrolladores, crear o ajustar aplicaciones adaptadas a las necesidades de nuestros clientes.'
        },
        {
            title: '¡Odoo puede con todo!',
            img: Fuerza,
            text: 'Desde PYMES hasta empresas grandes. Odoo cuenta con empresas que van desde 1 usuario hasta +300.000 usuarios activos.'
        },
    ]) 
    const [sectionTree, setSectionTree] = useState([
        {
            title: 'Nos adaptamos a Venezuela',
            img: Venezuela,
            text: 'Somos líderes en la localización Venezolana. Realizamos las adecuaciones necesarias para que tu negocio pueda operar en Venezuela.'
        },
        {
            title: 'Experiencia en distintas industrias',
            img: Figuras,
            text: 'Gracias a Odoo, hemos experimentado con distintos tipos de negocio, lo que nos permite tener un conocimiento amplio sobre distintas industrias.'
        },
    ]) 
    const [sectionFour, setSectionFour] = useState({
        img: OdooAmigos,
        title: 'Recuerda:',
        title2: '¡Hacemos equipo contigo!',
        button:'Solicita tu demo gratis'
    }) 
    const [data, setData] = useState([
        {
            // img: Testimony1,
            name: 'Maria Gabriela Chinchilla',
            job: 'Gerente de IT de Pits Auto Parts',
            testimony: '3MIT ha sido un excelente aliado, siempre estuvieron dispuestos a ser apoyo, lo que es fundamental para tener un buen enlace estratégico y que este proyecto saliera a ser uno ganador.'
        },
        {
            // img: Testimony1,
            name: 'Francisco Rodríguez',
            job: 'CEO de Dingo',
            testimony: 'Recomiendo a los consultores de 3MIT para la implementación de Odoo en cualquier negocio. Desde el comienzo notamos a un equipo motivado, profesional y responsable. 3MIT ha sido el aliado ideal para lograr nuestros objetivos base de negocio'
        },
    ])

    return (
        <>            
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
            >
                <Contact type="company" setModalIsOpen={setModalIsOpen} />
            </Modal>
            <HeaderComponent modalIsOpen={modalIsOpen}  />
            <HeroComponent data={hero} setModalIsOpen={setModalIsOpen} />
            { window.screen.width < 768 && <div style={{width: '100%', height: '150px'}} />}
            <p id="conoceOdoo" style={{margin: 0}}></p>
            <SectionWithText data={sectionWithText} />
            <Section data={sectionOne} background={colors.sectionBg} button='Conoce más' title="Una aplicación para cada necesidad" />
            <Section data={sectionTwo} title="¿Por qué Odoo?" />
            <p id="porque3mit" style={{padding: '10px 0 0 0', magin: 0}}></p>
            <Section data={sectionTree} background={colors.sectionBg} title="¿Por qué 3MIT?" />
            <SectionWithButton data={sectionFour} setModalIsOpen={setModalIsOpen} />
            <div style={{ width: '90%', margin: '50px auto', backgroundColor: colors.sectionBg, padding: '50px 0', borderRadius: 16 }}>
                <H2>Más de 1000 usuarios confían en nosotros con Odoo</H2>
                <Confidence img={Businesses} />
            </div>
            <Carousel data={data} title="No lo escuches de nosotros sino de ellos" />
            <Footer landing="odoo" modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
        </>
    )
}