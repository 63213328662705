
import styled from 'styled-components'
import { colors, size } from '../../styles/Global'

export const Hero = styled.div`
width: 100%;
height: 100vh;
background-image: url(${props => props.image});
background-size: contain;
background-repeat: no-repeat;
display: flex;
justify-content: center;
align-items: center;
@media (max-width: ${size.mobileM}) {
    /* background-image: none;
    background-color: ${props => props.backgroundColor}; */
  }
`

export const Img = styled.img`
width: 100%;
margin-bottom: 20px;
`
export const HeroInfoCcontainer = styled.div`
width: auto;
max-width: 550px;
margin-right: 40%;
@media (max-width: ${size.mobileM}) {
    width: 80%;
    margin: 10% auto;
  }
`
export const H1 = styled.h1`
margin: 0;
font-size: 48px;
color: #22326E;
@media (max-width: ${size.mobileM}) {
    font-size: 25px;
    text-align: center;
  }
`
export const H2 = styled.h1`
width: 100%;
text-align: center;
margin: 0;
font-size: 35px;
font-weight: 700;
color: #22326E;
/* @media (min-width: ${size.mobileM}) {
    font-size: 20px;
    text-align: center;
  } */
`
export const P = styled.div`
margin: 20px 0;
font-size: 24px;
color: #22326E;
font-weight: 600;
@media (max-width: ${size.mobileM}) {
    font-size: 20px;
    text-align: center;
  }
`
export const PrimaryButton = styled.button`
cursor: pointer;
color: ${colors.offWhite};
text-decoration: none;
font-size: 18px;
font-weight: bolder;
border: 3px solid ${colors.primaryIndigo};
padding: 12px 32px;
border-radius: 4px;
background: ${colors.primaryIndigo};
@media (max-width: ${size.mobileM}) {
    width: 100%;
}
`
export const PrimaryButtonLarge = styled.button`
color: ${colors.offWhite};
text-decoration: none;
font-size: 18px;
font-weight: bolder;
border: 3px solid ${colors.primaryIndigo};
padding: 12px 32px;
border-radius: 4px;
background: ${colors.primaryIndigo};
`
export const SecondaryButton = styled.p`
cursor: pointer;
text-decoration: none;
color: ${colors.primaryIndigo};
text-decoration: none;
font-size: 18px;
font-weight: bolder;
border: 3px solid ${colors.primaryIndigo};
padding: 12px 32px;
border-radius: 4px;
background: none;
@media (max-width: ${size.mobileM}) {
    /* width: 75%; */
    text-align: center;
}
`
export const ButtonsContainer = styled.div`
width: 400px;
display: flex;
justify-content: space-between;
align-items: center;
@media (max-width: ${size.mobileM}) {
    margin: 0 auto;
    flex-direction: column;
    width: 80%;
    height: 120px;
    justify-content: space-between;
    align-items: center;
  }
`