import React from 'react' 
import {
    MenuContainer,
    Navigation,
    MenuContent,
    Close
} from './Styles'
import CloseIcon from '../../assets/components/close.svg'
import '../header/modal.css'

export const MenuComponent = ({ isOpen, setIsOpen }) => {
    return (
        <MenuContainer isOpen={isOpen} >
            <MenuContent>
                <Close>
                    <img src={CloseIcon} onClick={()=> setIsOpen(false)}  />
                </Close>
                <Navigation to="/" className="navlink" activeClassName="selected">Inicio</Navigation>
                <Navigation to="/odoo" className="navlink" activeClassName="selected">Odoo</Navigation>
                <Navigation to="/desarrollo" className="navlink" activeClassName="selected">Desarrollo</Navigation>
                <Navigation to="/nosotros" className="navlink" activeClassName="selected">Nosotros</Navigation>
            </MenuContent>
        </MenuContainer>
    )
}