import styled from 'styled-components'
import { colors, size} from '../../styles/Global'

export const SectionContainer = styled.div`
    background-color: ${props => props.background ? props.background : 'white'};
    width: 80%;
    margin: 50px auto;
    padding: 50px 50px 50px;
    border-radius: 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: ${size.mobileM}) {
        flex-direction: column;    
        padding: 10px;
    }
`
export const TextContainer = styled.div`
    width: 50%;
    @media (max-width: ${size.mobileM}) {
        margin-top: 20px;
        width: 100%;
    }
`
export const ImageContainer = styled.div`
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: ${size.mobileM}) {
        width: 100%;
    }
`
export const Title = styled.p`
    margin: 0;
    font-size: 36px;
    font-weight: bold;
    color: ${colors.purpleText};
`
export const Text = styled.p`
    font-size: 16px;
    color: ${colors.purpleText};
`
export const TextLink = styled.p`
    /* color: ${colors.primaryIndigo}; */
    font-weight: bold;
`