import React from 'react' 
import {
    SectionContainer,
    TextContainer,
    ImageContainer,
    Title,
    Title2,
    Text,
    Span
} from './Styles'
import {
    PrimaryButton
} from '../hero/Styles'

export const SectionWithButton = ({data, background, setModalIsOpen}) => {
    return (
        <SectionContainer background={background}>
            <ImageContainer>
                <img src={data.img} />
            </ImageContainer>
            <TextContainer>
                {data.title && <Title>{data.title}</Title>}
                {data.text && <Text>{data.text}</Text>}
                {data.text2 && <Text>{data.text2}<Span>{data.span}</Span></Text>}
                <Title2>{data.title2}</Title2>
                <PrimaryButton onClick={()=> setModalIsOpen(true)}>{data.button}</PrimaryButton>
            </TextContainer>
        </SectionContainer>
    )
}