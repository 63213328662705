import React from 'react'
import {
    CardContainer,
    CardImage,
    Title,
    CardText
} from './Styles'

export const Card = ({ data, little, bold, noMargin }) => {
    return (
        <CardContainer little={little} noMargin={noMargin}>
            <CardImage src={data.img} />
            <div style={{height: 100}}>
                {
                    data.title && 
                    <Title>{data.title}</Title>
                }
                <CardText bold={bold ? bold : false}>{data.text}</CardText>
            </div>
        </CardContainer>
    )
}