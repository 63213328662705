import React, {useState} from 'react'  
import {
    colors,
    H2,
    PrimaryButton
} from '../../styles/Global'
import {
    Container,
    P,
    Span,
    Label,
} from './Styles'
import TextField from '@mui/material/TextField'
import './modal.css'
import Tag from '../../assets/tag.svg'
import Upload from '../../assets/upload.svg'
import Close from '../../assets/components/close.svg'
import Swal from 'sweetalert2'
import emailjs from "emailjs-com";
import ReactGA from 'react-ga';


export const Contact = ({ type, setModalIsOpen }) => {

    console.log('type', type)
    const [disable, setDisabled] = useState(false)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [company, setCompany] = useState('')
    const [description, setDescription] = useState('')
    // dev 
    const [cargo, setCargo] = useState('')
    const [cv, setCv] = useState('')
    const [file, setFile] = useState({})
    // doubt 
    const [doubt, setDoubt] = useState('')

    const ModalMessage = (title, text, icon, button) => {
        Swal.fire({
            title,
            text,
            icon,
            timer: 4000,
            // confirmButtonText: button,
            // confirmButtonColor: colors.primaryIndigo
        })
    }
    
    const sendData = () => {
        if(
            name.length > 0 &&
            phone.length > 0 &&
            email.length > 0 
        ){
            console.log(cv)
            handleSubmit()
            // ModalMessage(
                // 'Listo!', 
                // 'Tu mensaje fue enviado, contactaremos contigo en la brevedad posible',
                // 'success',
                // 'Entendido'
                // )
        }else{
            ModalMessage(
                'Hey!', 
                'Olvidaste llenar el resto de los campos',
                'warning',
                'Entendido'
                )
        }
    }

    const selectType =(type) => {
        if(type === 'work') {
            return {
                type: "Entrevista",
                name,
                email,
                phone,
                cargo,
                cv,
                file: file ? file : '',
                doubt,
                Attachments : [{
                     name: 'CV',
                     file: file 
                    }]
            }
        }
        else if(type === 'company') {
            return {
                type: "servicio",
                name,
                company,
                description,
                email,
                phone,
                doubt
            }
        }
        else if(type === 'doubt') {
            return {
                type: "Duda",
                name,
                email,
                phone,
                doubt
            }
        }
        else return;
    }

    const handleSubmit = () => {
        const data = selectType(type)
        console.log(data)
        const templateID = type === "company" ? process.env.REACT_APP_TEMPLATE_ODOO : process.env.REACT_APP_TEMPLATE_WORK
          setDisabled(true);
          emailjs
            .send(
              process.env.REACT_APP_SERVICE,
              templateID,
              data,
              process.env.REACT_APP_USER
            )
            .then(
              (result) => {
                console.log("resultado", result.text);
                setDisabled(false);
                ModalMessage(
                  "",
                  "Tu mensaje ha sido enviada",
                  "success",
                //   false,
                  4000
                );
              },
              (error) => {
                console.log(error.text);
                setDisabled(false);
                ModalMessage(
                  "",
                  "Hubo un problema :( inténtalo más tarde",
                  "error",
                //   false,
                  4000
                );
              }
            );
            ReactGA.event({
                category: data[type],
                action: 'Envio de data',
                label: 'El usuario envio sus datos para contacto'
              });
      };

    const changeFile = (e) => {
        let files = e.target.files;
        console.log(files);
        let filesArr = Array.prototype.slice.call(files);
        console.log(filesArr[0]);
        setCv(filesArr[0].name)
        setFile(filesArr[0])
    }

    const data = {
        "work": "Trabajo",
        "company": "Compañia",
        "doubt": "Duda",
    }
    

    return(
        <Container>
            <img src={Close} style={{marginLeft: 'auto', cursor: 'pointer'}} onClick={() => setModalIsOpen(false)}/>
            <img style={{width:80, display: 'block', margin: '10px auto'}} src={Tag} />
            { type === "work" && <H2 style={{marginBottom: 20}}>¡Seamos equipo!</H2>}
            { type === "company" && <H2 style={{marginBottom: 20}}>¡Comencemos!</H2>}
            { type === "doubt" && <H2 style={{marginBottom: 20}}>¿Dudas? ¡Te las aclaramos!</H2>}
            {
                type !== "work" &&
                <>
                <TextField 
                    id="outlined-basic" 
                    size='small' 
                    label="Nombre" 
                    variant="outlined" 
                    className="input-styled" 
                    value={name}
                    style={{margin: '10px 0'}}
                    onChange={(e) => setName(e.target.value)}/>
                <TextField 
                    id="outlined-basic" 
                    size='small' 
                    label="Teléfono" 
                    variant="outlined" 
                    className="input-styled" 
                    value={phone}
                    style={{margin: '10px 0'}}
                    onChange={(e) => setPhone(e.target.value)}/>
                <TextField 
                    id="outlined-basic" 
                    size='small' 
                    label="Correo" 
                    variant="outlined" 
                    className="input-styled" 
                    value={email}
                    style={{margin: '10px 0'}}
                    onChange={(e) => setEmail(e.target.value)}/>
                </>
            }
                {
                    type === "work" &&
                    <>
                    <P>Envianos tu CV a <Span>hola@3mit.dev</Span></P>
                        {/* <TextField 
                            size='small' 
                            label="Cargo de interés" 
                            variant="outlined" 
                            className="input-styled" 
                            value={cargo}
                            style={{margin: '10px 0'}}
                            onChange={(e) => setCargo(e.target.value)}/>
                        <Label>Adjuntar CV</Label>
                        <label className="input-file">
                            <input
                                type='file'
                                label="CV" 
                                className="input-file"
                                // value={cv}
                                onChange={(e) => changeFile(e)}/>
                                <img src={Upload} style={{width: '20px', display: 'block'}}/>
                        </label>
                        <p>{cv}</p> */}
                    </>
                }
                {

                    type === "company" &&
                    <>
                        <TextField 
                            size='small' 
                            label="Nombre de empresa" 
                            variant="outlined" 
                            className="input-styled" 
                            value={company}
                            style={{margin: '10px 0'}}
                            onChange={(e) => setCompany(e.target.value)}/>
                        <TextField 
                            label="¿En qué te podemos ayudar?" 
                            variant="outlined" 
                            className="input-styled"
                            multiline 
                            value={description}
                            style={{margin: '10px 0'}}
                            onChange={(e) => setDescription(e.target.value)}/>
                    </>
                }
                {
                    type === "doubt" && 
                    <TextField 
                        label="Duda" 
                        variant="outlined" 
                        className="input-styled"
                        multiline 
                        value={doubt}
                        style={{margin: '10px 0'}}
                        onChange={(e) => setDoubt(e.target.value)}
                    />
                }
           
            { type === 'company' && <PrimaryButton style={{marginTop: 20}} onClick={()=> sendData()} disable={disable}>Comencemos</PrimaryButton>}
            {/* { type === 'work' && <PrimaryButton style={{marginTop: 20}} onClick={()=> sendData()} disable={disable}>Postulate</PrimaryButton>} */}
            { type === 'doubt' && <PrimaryButton style={{marginTop: 20}} onClick={()=> sendData()} disable={disable}>Enviar</PrimaryButton>}
        </Container>
    )
}