import React from 'react'
import {
    ImplementationsContainer,
    ImplemetationsTitle,
    ImplemetationsText,
    CardsContainer,
    Div
} from './Styles'
import {
    PrimaryButton
} from '../../styles/Global'
import { Card } from './Card'

export const Implementations = ({ title, description, data, background, button }) => {

    const goto = () => {
        window.open('https://www.instagram.com/p/CI1ZJmFHTzg/', '_blank').focus();
    }

    return (
        <ImplementationsContainer background={background}>
            <Div>
                <ImplemetationsTitle>{title}</ImplemetationsTitle>
                <ImplemetationsText>{description}</ImplemetationsText>
            </Div>
            <CardsContainer>
                {
                    data.map(card => {
                        return (
                            <Card data={card} />
                        )
                    })
                }
            </CardsContainer>
            {
                data.length > 2 &&
                <PrimaryButton onClick={()=> goto()}>{button}</PrimaryButton>
            }
        </ImplementationsContainer>
    )
}