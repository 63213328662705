import styled from 'styled-components'
import { colors, size } from '../../styles/Global'
import Circle from '../../assets/us/circle.svg'

export const Ul = styled.ul`
position: relative;
width: 40%;
/* height: 693px; */
margin: 50px auto;
@media (max-width: ${size.mobileM}) {
    width: 85%;
    height: auto
}
`

export const Li = styled.li`
list-style-image: url(${Circle});
margin: 0 0 40px 0;
line-height: 30px;
padding-left: 20px;
font-size: 20px;
color: #22326E;
font-weight: 600;
`
export const Div = styled.div`
    width: 20px;
    height: 84%;
    left: 14px;
    top: 6px;
    background-color: rgba(208,220,249,1);
    position: absolute;
    z-index: -10;
    border-radius: 10px;
    @media (max-width: ${size.mobileM}) {
        display: none;
    }
`

export const VideoContainer = styled.div`
    width: 70%;
    border-radius: 5px;
    background-color: ${colors.primaryIndigo};
    margin: 100px auto;
    overflow: hidden;
    @media (max-width: ${size.mobileM}) {
        width: 100%;
        border-radius: 0;
    }
`