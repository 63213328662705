import React, { useState, useEffect } from 'react'  
import { HeaderComponent } from '../../components/header/Header'
import {
    Div,
    Img
} from './Styles'
import { colors, H2 } from '../../styles/Global'
import YummyGray from '../../assets/dev/yummygray.svg'
import DingoGray from '../../assets/dev/dingogray.svg'
import MitasGray from '../../assets/dev/mitasgray.svg'
import Developer from '../../assets/dev/developer.svg'
import Modal from 'react-modal';
import { Contact } from '../../components/header/Contact'
// hero 
import HeroImg from '../../assets/dev/hero.svg'
import HeroImgRes from '../../assets/dev/hero_res.svg'
import { HeroComponent } from '../../components/hero/Hero'
// seccion 1
import { SectionWithText } from '../../components/sectionWithText/SectionWithText'
// seccion 2
import { Section } from '../../components/section/Section'
import Negocios from '../../assets/dev/negocios.svg'
import Cohete from '../../assets/dev/cohete.svg'
// seccion 3
import { SectionWithButton } from '../../components/sectionWithButton/SectionWithButton'
import OdooAmigos from '../../assets/odoo/odooAmigos.svg'
// seccion 4 
import { Implementations } from '../../components/implementations/Implementations'
import Yummy from '../../assets/dev/yummy_.svg'
import Dingo from '../../assets/dev/dingo_.svg'
import Mitas from '../../assets/dev/mitas.svg'
// Carousel 
import { Carousel } from '../../components/carousel/Carousel'
import Testimony1 from '../../assets/testimonies/testimony1.svg'
import Testimony2 from '../../assets/testimonies/testimony2.svg'
// footer 
import { Footer } from '../../components/footer/Footer'

import ReactGA from 'react-ga';


 
export const Dev = () => {

    useEffect(() => {
        ReactGA.pageview('/desarrollo');
      }, []);

    const dev = false
    const [modalIsOpen ,setModalIsOpen] = useState(false)
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };
    const [hero, setHero] = useState({
        heroImg: window.screen.width > 480 ? HeroImg : HeroImgRes,
        title1: 'Hacemos tu idea',
        title2: 'realidad por más',
        title3: 'ambiciosa que sea',
        text: 'Creamos el producto digital que necesita tu empresa',
        buttons: [
            {
                type: 'primary',
                text: 'Comencemos'
            }
        ]
    })

    const [sectionWithText, setSectionWithText] = useState({
        img: Developer,
        title: '¿Por qué necesitas desarrollo de software?',
        text: 'Si lo que buscas es que tu negocio esté lo más personalizado posible, contar con desarrollo de software es la respuesta. De esta manera, puedes contar con herramientas propias y exclusivas a las necesidades de tu empresa. Una aplicación, un módulo único dentro de Odoo, una página web, lo que necesites...',
        link: '¡Cuéntanos tu idea y la hacemos realidad!'
    })

    const [sectionOne, setSectionOne] = useState([
        {
            title: 'Sabemos de negocios',
            img: Negocios,
            text: 'No solo conocemos sobre tecnología, gracias a Odoo entendemos lo necesario para optimizar cualquier proceso empresarial con productos digitales.'
        },
        {
            title: 'Tenemos experiencia creando startups',
            img: Cohete,
            text: 'Sabemos lo que conlleva iniciar un negocio digital. '
        }
    ]) 

    const [sectionTwo, setSectionTwo] = useState({
        img: OdooAmigos,
        title: 'Cuéntanos tu idea y la ',
        title2: 'hacemos realidad',
        button:'Contáctanos'
    }) 

    const [implementations, setImplementations] = useState([
        {
            background: '#CFF6DF',
            title: 'Desarrollo de app de ridesharing',
            img: Yummy,
            text: '',
        },
        {
            background: '#3D6BE3',
            title: 'Automatización de WhatsApp',
            img: Dingo,
            text: '',
        },
        {
            background: '#F5E9A9',
            title: 'Exchange con criptomonedas',
            img: Mitas,
            text: '',
        }
    ]) 
    const [data, setData] = useState([
        {
            // img: Testimony1,
            name: 'Francisco Rodríguez',
            job: 'CEO de Dingo',
            testimony: '3MIT nos apoyó con la creación de un módulo personalizado desde Odoo y un WhatsApp Automatizado para ofrecer una experiencia de usuario única para nuestros clientes. 3MIT es la perfecta combinación entre organizar procesos bases de negocio e innovarlos con tecnología'
        },
        {
            // img: Testimony1,
            name: 'Ignacio Carrillo',
            job: 'Gerente de Producto en Yummy',
            testimony: '3MIT es una empresa comprometida que prepara de forma excelente a sus integrantes, haciendo que lleven a cabo de manera efectiva y responsable, cualquier proyecto que se les asigna. Seguiremos cultivando nuestra alianza a futuro'
        },
    ])
    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
            >
                <Contact type="company" setModalIsOpen={setModalIsOpen} />
            </Modal>
            <HeaderComponent dev={true} modalIsOpen={modalIsOpen}  />
            <HeroComponent data={hero} setModalIsOpen={setModalIsOpen} />
            { window.screen.width < 768 && <div style={{width: '100%', height: '150px'}} />}
            <H2 style={{margin: window.screen.width > 480 ? '100px 0' : '20px 0 50px'}}>Hacemos equipo con las marcas más novedosas</H2>
            <Div>
                <Img src={YummyGray}/>
                <Img src={DingoGray}/>
                <Img src={MitasGray}/>
            </Div>
            <p id="porqueDev" style={{padding: '10px 0 0 0', magin: 0}}></p>
            <SectionWithText data={sectionWithText} background={colors.sectionBg} />
            <p id="porque3mit2" style={{padding: '10px 0 0 0', magin: 0}}></p>
            <Section data={sectionOne} title="¿Por qué 3MIT?" />
            <SectionWithButton data={sectionTwo} background={colors.sectionBg} setModalIsOpen={setModalIsOpen}/>
            <Implementations title='Creamos productos digitales que superan cualquier ambición' description='' data={implementations} button="Conoce nuestro trabajo" />
            <Carousel data={data} background={colors.sectionBg} title="No lo escuches de nosotros sino de ellos" />
            <Footer landing="dev" modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
        </>
    )
}