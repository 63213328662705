import React from 'react' 
import {
    SectionContainer,
    TextContainer,
    Title,
    ImageContainer,
    Text,
    TextLink
} from './Styles'

export const SectionWithText = ({data, background}) => {
    return (
        <SectionContainer background={background}>
            <ImageContainer>
                <img src={data.img} />
            </ImageContainer>
            <TextContainer>
                <Title>{data.title}</Title>
                <Text>{data.text}</Text>
                {data.link && <TextLink>{data.link}</TextLink>}
            </TextContainer>
        </SectionContainer>
    )
}