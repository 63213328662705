import React, { useState } from 'react'
import {
    FooterContainer,
    // left 
    LeftSection,
    LogosContainer,
    InfoContainer,
    ContactContainer,
    SocialMedia,
    WhatsAppButton,
    // right 
    RightSection,
    Box,
    Nav,
    Form,
    A
} from './Styles'
import TreemitLogo from '../../assets/3mit_logo.svg'
import OdooLogo from '../../assets/odoo_logo.svg'
import wsLogo from '../../assets/ws_logo.svg'
import Ig from '../../assets/ig.svg'
import In from '../../assets/in.svg'
import Yt from '../../assets/yt.svg'

import { Contact } from '../../components/header/Contact'
import Modal from 'react-modal';
import {useHistory, useLocation} from 'react-router-dom'
import { Link, animateScroll as scroll } from "react-scroll";
import ReactGA from 'react-ga';


export const Footer = ({ landing }) => {
    const [modalIsOpen ,setModalIsOpen] = useState(false)
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          marginTop: '30px',
          transform: 'translate(-50%, -50%)',
        },
      };

      const handleClick = () => {
        ReactGA.event({
            category: 'Contacto',
            action: 'boton de contacto',
            label: 'El usuario presiono el boton que redirige al whatsapp'
          });
    }

    return (
        <>
        <Modal
            isOpen={modalIsOpen}
            style={customStyles}
        >
            <Contact type="doubt" setModalIsOpen={setModalIsOpen} />
        </Modal>
        <FooterContainer>
            <LeftSection>
                <LogosContainer>
                    <img src={TreemitLogo} />
                    <img style={{marginTop: 10}} src={OdooLogo} />
                </LogosContainer>
                <InfoContainer>
                    <p>Somos una fábrica de software y consultora de negocios que acompaña a todo tipo de empresas a optimizar sus procesos.</p>
                </InfoContainer>
                <ContactContainer>
                    <p style={{fontSize: 20}}>Contacta con nosotros en nuestras redes</p>
                    <SocialMedia>
                        <WhatsAppButton target="_blank" href={`https://wa.me/${process.env.REACT_APP_WS_3MIT}`}><img src={wsLogo} style={{marginRight: 5}} onClick={handleClick} />Contáctanos</WhatsAppButton>
                        <a target="_blank" href={`https://www.instagram.com/3mit.dev/`} >
                            <img src={Ig} />
                        </a>
                        <a target="_blank" href={`https://www.linkedin.com/company/3mit/`}>
                            <img src={In} />
                        </a>
                        <a target="_blank" href={`https://www.youtube.com/channel/UCNYTP67sdCZjBkqJxrZUz0A`}>
                            <img src={Yt} />
                        </a>
                    </SocialMedia>
                </ContactContainer>
                <InfoContainer>
                    <p>Dirección: Avenida Principal de Los Ruices, Oficentro Los Ruices, Oficina PBC, Caracas, Venezuela.</p>
                </InfoContainer>
            </LeftSection>
            <RightSection>
                <Box>
                    <Nav to="/odoo">Odoo</Nav>
                    <p>Conoce Odoo</p>
                    <p>Por qué 3MIT</p>
                    <p>Por qué Odoo</p>
                    <Form style={{marginTop: 50}} onClick={()=> setModalIsOpen(true)}>Dudas</Form>
                    <p>Preguntas</p>
                </Box>
                <Box>
                    <Nav to="/desarrollo">Desarrollo</Nav>
                    <p>Por qué desarrollo</p>
                    <p>Por qué 3MIT</p>
                    <p>Conoce nuestro trabajo</p>
                </Box>
                <Box>
                    <Nav to="/nosotros">Nosotros</Nav>
                    <p>Nuestra historia</p>
                    <p>Nuestros pilares</p>
                    <p>Postúlate</p>
                </Box>
            </RightSection>
        </FooterContainer>
        </>
    )
}