import React, { useEffect } from 'react'
import './App.css';
import { Landing } from './views/landing/landing'
import { Odoo } from './views/odoo/Odoo'
import { Dev } from './views/dev/Dev'
import { Us } from './views/us/Us'
import {Route, Switch, BrowserRouter } from "react-router-dom";
import { createGlobalStyle } from 'styled-components'
import ScrollToTop from './ScrollToTop'
import ReactGA from 'react-ga';


function App() {

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID, { debug: true });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Mulish', sans-serif;
    position: relative;
  }
`;

  return (
    <BrowserRouter>
    <GlobalStyle />
    <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Landing}/>
        <Route exact path="/odoo" component={Odoo}/>
        <Route exact path="/desarrollo" component={Dev}/>
        <Route exact path="/nosotros" component={Us}/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
