import styled from 'styled-components'
import { colors, size } from '../../styles/Global'


export const CardsContainer = styled.div`
    width: 70%;
    margin: 100px auto;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    @media (max-width: ${size.mobileM}) {
        width: 90%;
        overflow-y: auto;
        justify-content: space-between;
        ::-webkit-scrollbar {
    height: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: #E5E5E5;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 6px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #B8B8B8;
    }
    }
`
export const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: ${size.mobileM}) {
        margin: 0 30px
    }
`
export const CardImage = styled.img`
    display: block;
    margin: auto;
`