import React, { useState } from 'react'
// images 
import Finanzas from '../../assets/landing/finanzas.svg'
import Retail from '../../assets/landing/retail.svg'
import Automotriz from '../../assets/landing/automotriz.svg'
import Delivery from '../../assets/landing/delivery.svg'
import Salud from '../../assets/landing/salud.svg'
// styles
import {
    CardsContainer,
    Card,
    CardImage,
} from './Styles'
import {
    H2,
    P
} from '../../styles/Global'

export const Industries = () => {
    const [cards, sdetCards] = useState([
        {
            title: 'Finanzas',
            img: Finanzas
        },
        {
            title: 'Retail',
            img: Retail
        },
        {
            title: 'Automotriz',
            img: Automotriz
        },
        {
            title: 'Delivery',
            img: Delivery
        },
        {
            title: 'Salud',
            img: Salud
        },
    ])
    return (
        <>
            <H2>Industrias en las que participamos</H2>
            <CardsContainer>
                {
                    cards.map(card => {
                        return(
                            <Card>
                                <CardImage src={card.img} />
                                <P>{card.title}</P>
                            </Card>
                        )
                    })
                }
            </CardsContainer>
        </>
    )
}