import styled from 'styled-components'
import { colors, size} from '../../styles/Global'

export const Container = styled.div`
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 16px;
    background-color: ${props => props.background ? props.background : "transparent" };
    @media (max-width: ${size.mobileM}) {
        
    }
`
export const ContainerImage = styled.div`
    width: 70%;
    margin: 0 auto;
    overflow-x: auto;
    padding: 0 20px;
    ::-webkit-scrollbar {
    height: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: #E5E5E5;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 6px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #B8B8B8;
    }
`
export const Image = styled.img`
    margin: 50px auto;
    display: block;
    @media (max-width: ${size.mobileM}) {
        
    }
`
export const Arrow = styled.img`
    display: block;
    @media (max-width: ${size.mobileM}) {
        
    }
`