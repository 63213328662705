import styled from 'styled-components'
import { colors, size } from '../../styles/Global'

export const CardContainer = styled.div`
    margin: 20px auto;
    width: 360px;
    height: 180px;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 8px;
    padding: 30px;
    @media (max-width: ${size.mobileM}) {
        width: 200px;
        height: 350px;
    }
`
export const PersonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${size.mobileM}) {
        flex-direction: column;
        justify-content: center;
    }
`
export const PersonInfo = styled.div`
    margin-right: 20px;
`
export const Name = styled.p`
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: ${colors.purpleText};
    @media (max-width: ${size.mobileM}) {
        font-size: 20px;
        margin-top: 10px;
    }
`
export const Job = styled.div`
    margin: 0;
    font-size: 20px;
    color: ${colors.purpleText};
`
export const Testimony = styled.div`
    margin: 20px 0;
    font-size: 16px;
    font-style: italic;
    color: ${colors.purpleText};
`