import styled from 'styled-components'
import { colors, size } from '../../styles/Global'
import { NavLink } from 'react-router-dom'

export const FooterContainer = styled.footer`
    box-sizing: border-box;
    width: 100%;
    height: 350px;
    background-color: ${colors.footer};
    display: flex;
    padding: 50px 100px;
    color: white;
    @media (max-width: ${size.mobileM}) {
        display: block;
        height: auto;
        padding: 30px;
    }
`
// left 
export const LeftSection = styled.div`
width: 50%;
@media (max-width: ${size.mobileM}) {
    width: 100%;
}
`
export const LogosContainer = styled.div`
    /* max-width: 60%; */
    max-width: 315px;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${size.mobileM}) {
        margin: 40px 0;
    }
`
export const InfoContainer = styled.div`
font-size: 12px;
margin-top: 20px;
`
export const ContactContainer = styled.div`

`
export const SocialMedia = styled.div`
width: 80%;
display: flex;
justify-content: space-between;
align-items: center;
@media (max-width: ${size.mobileM}) {
    width: 100%;
}

`
export const WhatsAppButton = styled.a`
color: white;
text-decoration: none;
border-radius: 5px;
display: flex;
justify-content: space-evenly;
align-items: center;
width: 180px;
padding: 10px;
background-color: #54D26F;
font-size: 18px;
font-weight: bold;
@media (max-width: ${size.mobileM}) {
    width: auto;
    font-size: 14px;
    /* margin-left: 5px; */
}
`
// right 
export const RightSection = styled.footer`
width: 50%;
display: flex;
flex-wrap: wrap;
color: lightgray;
@media (max-width: ${size.mobileM}) {
    width: 100%;
    margin-top: 50px;
}
`
export const Box = styled.footer`
width: 25%;
margin: 0 auto;
font-size: 18px;
& p {
    margin: 5px 0;
}
@media (max-width: ${size.mobileM}) {
    font-size: 14px;
}
`
export const Nav = styled(NavLink)`
color: white;
text-decoration: none;
font-weight: bolder;
font-size: 18px;

@media (max-width: ${size.mobileM}) {
    font-size: 14px;
}
`
export const Form = styled.p`
cursor: pointer;
color: white;
margin-top: 50px;
font-weight: bolder;
font-size: 18px;
@media (max-width: ${size.mobileM}) {
    font-size: 14px;
}
`
export const A = styled.a`
text-decoration: none;
color: white;
margin-top: 50px;
font-weight: bolder;
font-size: 18px;
@media (max-width: ${size.mobileM}) {
    font-size: 14px;
}
`