import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { colors, size } from '../../styles/Global'

export const Header = styled.header`
background-color: ${props => props.background ? '#fff' : 'transparent'};
box-shadow: ${props => props.background ? '0px 4px 24px rgb(0, 0, 0, 0.20)' : 'transparent'};
position: fixed;
width: 100%;
margin: 0 auto;
padding: 20px 0;
transition: 0.3s;
z-index: 1000;
@media (max-width: ${size.mobileM}) {
    /* padding: 20px 0 20px; */
}
`;

export const HeaderContent = styled.div`
width: 80%;
margin: 0 auto;
display: flex;
justify-content: center;
align-items: center;
@media (max-width: ${size.mobileM}) {
    justify-content: space-between;
}
`;

export const LogoWrapper = styled.div`
margin-right: auto;
@media (max-width: ${size.mobileM}) {
    margin: 0
}
`;
export const LinksWrapper = styled.div`
margin-right: 100px;
@media (max-width: ${size.mobileM}) {
    display: none;
}
`;
export const ContactWrapper = styled.div`
margin-left: 0;
@media (max-width: ${size.mobileM}) {
    display: none;
}
`;
export const Navigation = styled(NavLink)`
margin-left: 20px;
/* color: ${colors.lightGray}; */
text-decoration: none;
font-size: 18px;
font-weight: bolder;
`;

export const NavigationContact = styled.a`
text-decoration: none;
color: ${colors.primaryIndigo};
text-decoration: none;
font-size: 18px;
font-weight: bolder;
border: 3px solid ${colors.primaryIndigo};
padding: 12px 32px;
border-radius: 4px;
`;
// modal 

export const Container = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 50px; */
    z-index: 3463456;
    @media (max-width: ${size.mobileM}) {
        width: 100%;
    }
`
export const Input = styled.input`
    width: 80%;
    height: 40px;
    margin: 10px auto;
    border: 1px solid ${colors.lightGray};
    border-radius: 10px;
    font-size: 18px;
`;
export const Label = styled.p`
    /* width: 100%; */
    text-align: center;
    margin: 10px 0 5px;
    font-size: 16px;
`;
export const P = styled.p`
    /* width: 100%; */
    text-align: center;
    margin: 10px 0 5px;
    font-weight: bold;
    font-size: 20px;
    color: ${colors.lightGray};;
`;
export const Span = styled.span`
    /* width: 100%; */
    text-align: center;
    margin: 10px 0 5px;
    font-weight: bold;
    font-size: 20px;
    color: ${colors.primaryIndigo};;
`;
