import React, { useState, useEffect } from 'react'
import { HeaderComponent } from '../../components/header/Header'
import HeroImg from '../../assets/landing/hero.svg'
import HeroImgRes from '../../assets/landing/hero_res.svg'
import Modal from 'react-modal';
import { Contact } from '../../components/header/Contact'

import { 
    Hero,
    HeroInfoCcontainer,
    H1,
    H2,
    P,
    PrimaryButton,
    SecondaryButton,
    CTA2
 } from '../../styles/Global'
import { VideoContainer } from '../us/Styles'
import '../us/us.css'
import { colors } from '../../styles/Global'
import { Section } from '../../components/section/Section'
import { Implementations } from '../../components/implementations/Implementations'
import { Industries } from '../../components/industries/Industries'
import { Footer } from '../../components/footer/Footer'
// Hero
import { HeroComponent } from '../../components/hero/Hero'
// sections 
import Referencia from '../../assets/landing/referencia.svg'
import Desarrollo from '../../assets/landing/desarrollo.svg'
import EquipoInnovador from '../../assets/landing/equipo_innovador.svg'
import AnalizamosNegocio from '../../assets/landing/analizamos_negocio.svg'
import DisenamosEstrategia from '../../assets/landing/disenamos_estrategia.svg'
import ImplementamosSolucion from '../../assets/landing/implementamos_solucion.svg'
// implementations 
import ImplementacionOdoo from '../../assets/landing/implementation_odoo.svg'
import ImplementacionDev from '../../assets/landing/implementation_dev.svg'
// condifence  
import { Confidence } from '../../components/confidence/Confidence'
import Businesses from '../../assets/landing/confidence.svg'

import ReactGA from 'react-ga';

export const Landing = () => {

    useEffect(() => {
        ReactGA.pageview('/homepage');
      }, []);
      
      const dev = false
      const [modalIsOpen ,setModalIsOpen] = useState(false)

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };
    const [hero, setHero] = useState({
        heroImg: window.screen.width > 480 ? HeroImg : HeroImgRes,
        title1: 'Impulsamos',
        title2: 'tu negocio con',
        title3: 'tecnología',
        text: 'Nos adaptamos a tu medida',
        buttons: [
            {
                type: 'primary',
                text: 'Comienza ya'
            },
            {
                type: 'secondary',
                text: 'Conoce más'
            },
        ]
    })
    const [sectionOne, setSectionOne] = useState([
        {
            title: 'Referencia #1',
            img: Referencia,
            text: 'Somos el primer Odoo Gold Partner en Venezuela.'
        },
        {
            title: 'Equipo Innovador',
            img: EquipoInnovador,
            text: 'Somos un equipo joven, ambicioso y comprometido. Contamos con gran conocimiento en las más recientes tecnologías.'
        },
        {
            title: 'Desarrollo de Software',
            img: Desarrollo,
            text: 'Único Odoo Partner en Venezuela con especial foco en esta área.'
        },
    ]) 
    const [sectionTwo, setSectionTwo] = useState([
        {
            title: 'Analizamos tu negocio',
            img: AnalizamosNegocio,
            text: 'Estudiamos la situación actual de tu empresa para entender qué áreas deben ser atendidas.'
        },
        {
            title: 'Diseñamos la estrategia',
            img: DisenamosEstrategia,
            text: 'A partir de ese estudio, obtenemos información de valor que nos permitirá ofrecer una solución única, medible y acorde a la necesidad actual de tu empresa.'
        },
        {
            title: 'Implementamos la solución',
            img: ImplementamosSolucion,
            text: 'Con apoyo de Odoo y Desarrollo de Software.'
        },
    ]) 
    const [implementations, setImplementations] = useState([
        {
            background: '#EEE1F5',
            title: 'Implementacion de Odoo',
            img: ImplementacionOdoo,
            text: 'Un sistema empresarial integrado, simple, versátil, adaptable y rentable.',
            knowMore: true,
            odoo: true
        },
        {
            background: '#CFF6DF',
            title: 'Desarrollo de Software',
            img: ImplementacionDev,
            text: 'Creamos el producto digital que necesita tu empresa (un módulo para Odoo, una aplicación…).',
            knowMore: true,
            odoo: false
        }
    ]) 

    const handlePlayVideo = () => {
        console.log("PLAAAAAAAAAY")
    }
    return(
        <>
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
            >
                <Contact type="company" setModalIsOpen={setModalIsOpen} />
            </Modal>
            <HeaderComponent modalIsOpen={modalIsOpen}  />
            <HeroComponent data={hero} setModalIsOpen={setModalIsOpen} />
            <H2 style={{paddingTop: '150px', width: "80%", margin: "0 auto"}} id="confidence">Ellos salieron de su zona de confort e innovaron sus procesos de negocio con nosotros</H2>
            <Confidence img={Businesses} />
            <H2 style={{paddingTop: '150px'}}>¡Conócenos!</H2>
            <VideoContainer>
                <div className="videoContainer">
                    <iframe 
                    src="https://www.youtube.com/embed/su01gJTrdKc" 
                    title="YouTube video player" 
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                    autoplay={1}
                    width="560" height="315"
                    ></iframe>
                </div>
            </VideoContainer>
            <Section data={sectionOne} background={colors.sectionBg} title="¿Por qué 3MIT?" />
            <Section data={sectionTwo} title="Cómo trabajamos" />
            <Implementations title='Nos adaptamos a ti' description='Nuestra solución es completamente personalizada a la realidad de tu negocio.' data={implementations} background={colors.sectionBg} />
            <Industries />
            <CTA2>
                <H2>¡Forma parte del grupo de empresas que confiaron en nosotros para impulsar su negocio!</H2>
                <PrimaryButton onClick={()=> setModalIsOpen(true)}>Comienza ya</PrimaryButton>
            </CTA2>
            <Footer landing="main" modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
        </>
    )
}