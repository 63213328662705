import React, { useState } from 'react'
import { Card } from './Card.js'
import {
    CardsContainer,
    SectionTitle,
    SectionContainer,
} from './Styles'
import {
    PrimaryButton
} from '../hero/Styles'

export const Section = ({ data, background, button, bold, title }) => {

    const goto = () => {
        window.open('https://www.odoo.com/es_ES/page/all-apps', '_blank').focus();
    }

    return (
        <SectionContainer background={background} >
            <SectionTitle>{title}</SectionTitle>
            <CardsContainer>
                {
                    data.map(card => {
                        let noMargin;
                        if(data.length > 3 ) noMargin = true
                        else noMargin = false
                        return (
                            <Card data={card} little={card.little ? card.little : false} bold={bold} noMargin={noMargin} />
                        )
                    })
                }
            </CardsContainer>
            {
                button && <PrimaryButton onClick={()=> goto()} style={{display: 'block', margin: '0 auto'}}>{button}</PrimaryButton>
            }
            
        </SectionContainer>
    )
}