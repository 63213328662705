import React, { useState, useEffect } from 'react' 
import HeroImg from '../../assets/us/hero.svg'
import HeroImgRes from '../../assets/us/hero_res.svg'
import TeanWork from '../../assets/us/equipo_innovador.svg'
import { SectionWithText } from '../../components/sectionWithText/SectionWithText'
import { colors, H2 } from '../../styles/Global'
import { Ul, Li, Div, VideoContainer } from './Styles'
import './us.css'
import Modal from 'react-modal';
import { Contact } from '../../components/header/Contact'
// header 
import { HeaderComponent } from '../../components/header/Header'
// hero 
import { HeroComponent } from '../../components/hero/Hero'
// seccion 
import { Section } from '../../components/section/Section'
import Equipo from '../../assets/us/equipo.svg'
import Innovacion from '../../assets/us/innovacion.svg'
import Compromiso from '../../assets/us/compromiso.svg'
import Ambicion from '../../assets/us/ambicion.svg'
import Escalabilidad from '../../assets/us/escalabilidad.svg'
import Proactividad from '../../assets/us/proactividad.svg'
import Transparencia from '../../assets/us/transparencia.svg'
import Aprendizaje from '../../assets/us/aprendizaje.svg'
import Optimismo from '../../assets/us/optimismo.svg'
// seccion 2
import Integraciones from '../../assets/odoo/integraciones.svg'
import Dev from '../../assets/odoo/dev.svg'
import Fuerza from '../../assets/odoo/fuerza.svg'
// seccion 3
import Venezuela from '../../assets/odoo/venecozuela.svg'
import Figuras from '../../assets/odoo/figuras.svg'
// seccion 4 
import { SectionWithButton } from '../../components/sectionWithButton/SectionWithButton'
import Hands from '../../assets/us/hands.svg'
// seccion 5
import Confidence from '../../assets/landing/confidence.svg'
// Carousel 
import { Carousel } from '../../components/carousel/Carousel'
import Testimony1 from '../../assets/testimonies/testimony1.svg'
import Testimony2 from '../../assets/testimonies/testimony2.svg'
// footer 
import { Footer } from '../../components/footer/Footer'
import ReactGA from 'react-ga';

export const Us = () => {

    useEffect(() => {
        ReactGA.pageview('/nosotros');
      }, []);

    const [modalIsOpen ,setModalIsOpen] = useState(false)
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };

    const [hero, setHero] = useState({
        heroImg: window.screen.width > 480 ? HeroImg : HeroImgRes,
        title1: 'Te acompañamos',
        title2: 'a enfrentar el mundo',
        title3: 'del mañana',
        text: 'Nuestro principal aliado es la tecnología',
        buttons: [
            {
                type: 'secondary',
                text: 'Conoce más'
            },
        ]
    })
    const [sectionWithText, setSectionWithText] = useState({
        img: TeanWork,
        title: '3MIT',
        text: `Somos una fábrica de software y consultora de negocios que acompaña a todo tipo de empresas a optimizar sus procesos. 
        
        \nNuestro principal objetivo es transformar los procesos de las empresas, apoyándonos de la tecnología para que nuestros clientes puedan ahorrar tiempo, dinero y enfocarse en aquellas tareas que realmente requieren de su atención.`
    })
    const [sectionOne, setSectionOne] = useState([
        {
            img: Equipo,
            text: 'Equipo',
            little: true
        },
        {
            img: Innovacion,
            text: 'Innovación',
            little: true
        },
        {
            img: Compromiso,
            text: 'Compromiso',
            little: true
        },
        {
            img: Ambicion,
            text: 'Ambición',
            little: true
        },
        {
            img: Escalabilidad,
            text: 'Escalabilidad',
            little: true
        },
        {
            img: Proactividad,
            text: 'Proactividad',
            little: true
        },
        {
            img: Transparencia,
            text: 'Transparencia',
            little: true
        },
        {
            img: Aprendizaje,
            text: 'Aprendizaje',
            little: true
        },
        {
            img: Optimismo,
            text: 'Optimismo',
            little: true
        },
    ]) 

    const [data, setData] = useState([
        {
            // img: Testimony1,
            name: 'Miguel Medina',
            job: 'CEO',
            testimony: '3MIT es un equipo creativo, ambicioso y que está en constante reto. Estamos comprometidos con lograr la innovación tecnológica que queremos para el país y para el mundo, entendiendo que todo está en nuestras manos'
        },
        {
            // img: Testimony2,
            name: 'Michel Castillo',
            job: 'Consultor de Odoo',
            testimony: '3MIT nos motiva a siempre querer subir de nivel como personas y profesionales'
        },
        {
            // img: Testimony1,
            name: 'Anais Mota',
            job: 'Consultor de Odoo',
            testimony: '3MIT es engranaje y trabajo en equipo'
        },
        {
            // img: Testimony2,
            name: 'Maria Gabriela Carreño',
            job: 'Desarrolladora de Odoo',
            testimony: '3MIT es ambición. La ambición ha hecho que podamos posicionarnos en donde todos queremos estar'
        },
        {
            // img: Testimony1,
            name: 'Jesús Adráz',
            job: 'Consultor de Odoo',
            testimony: 'Somos un equipo temerario, estamos frente a los retos, siempre resolvemos y nos superamos'
        },
        {
            // img: Testimony1,
            name: 'César Ramírez',
            job: 'Consultor de Odoo',
            testimony: 'Para mi 3MIT es compañerismo. Nos ampliamos rápido y siempre he sentido que tenemos el apoyo de todos para lograr las metas'
        },
        {
            // img: Testimony1,
            name: 'Yorman Pineda',
            job: 'Desarrollador de Odoo',
            testimony: 'En 3MIT se refleja seguridad tanto internamente como con los clientes. Estamos seguros que siempre cumpliremos y daremos la milla extra mientras crecemos constantemente'
        },
        {
            // img: Testimony1,
            name: 'Ismael Castillo',
            job: 'Desarrollador de Odoo',
            testimony: 'Para mi 3MIT significa innovación. Es una empresa que innova con sus ideas, su forma de organizarse y con lo que ofrece como empresa. Venezuela necesita innovación tecnológica'
        },
        {
            // img: Testimony1,
            name: 'Kleiver Perez',
            job: 'Desarrollador de Odoo',
            testimony: '3MIT me dio ánimos y ganas de superarme. La pandemia me dejó en un hilo y nunca pensé que iba a trabajar. Me dio la oportunidad de expandir mis atributos'
        },
        {
            // img: Testimony1,
            name: 'Francisco Bastardo',
            job: 'Desarrollador de Odoo',
            testimony: '3MIT me hace estar en constante reto. Una forma de sobreponerse a las adversidades de la pandemia y relacionarme con un buen equipo de trabajo'
        },
        {
            // img: Testimony1,
            name: 'Andrea Covelo',
            job: 'Marketing Manager',
            testimony: '3MIT apuesta por ti para que aprendas, crezcas con la empresas y que la empresa crezca contigo'
        },
        {
            // img: Testimony1,
            name: 'Maria Emilia Villarroel',
            job: 'Consultor de Odoo',
            testimony: '3MIT es un reto y aprendizaje constante'
        },
        {
            // img: Testimony1,
            name: 'Freddy Castillo',
            job: 'Desarrollador de Odoo',
            testimony: '3MIT es esperanza. Es una forma de sentir que tenemos la oportunidad y confianza para seguir construyendo lo que queremos en Venezuela'
        },
        {
            // img: Testimony1,
            name: 'Raúl Millán',
            job: 'Consultor de Odoo',
            testimony: '3MIT para mi significa trabajo en equipo, aprendizaje, juventud, planificación y coordinación'
        },
        {
            // img: Testimony1,
            name: 'Victor Del Carpio',
            job: 'CTO',
            testimony: '3MIT es futuro. Nos formamos hoy visualizándonos para todo lo que viene en el mañana'
        },
        {
            // img: Testimony1,
            name: 'Reinaldo Orellana',
            job: 'Consultor Contabilidad Odoo',
            testimony: '3MIT es organización por la forma en la que buscan estructurarse como empresa y sus procesos internos'
        },
        {
            // img: Testimony1,
            name: 'Nayeska Granado',
            job: 'Consultor de Odoo',
            testimony: '3MIT es gratitud. Agradezco todas las oportunidades de crecimiento que nos ofrece la empresa'
        },
        {
            // img: Testimony1,
            name: 'Rodolfo Díaz',
            job: 'Consultor de Odoo',
            testimony: '3MIT es un equipo proactivo, con iniciativa y confianza, lo que la hace generar los resultados que logra hoy en dia'
        },
    ])

    const [sectionFour, setSectionFour] = useState({
        img: Hands,
        text: 'Equipo, ambición, reto, innovación, crecimiento y futuro, son palabras clave que nos definen.',
        text2: `Si te sientes identificado,`,
        title2: '¡Estamos contratando!',
        span: ' 3MIT es el lugar para ti.',
        button:'Postúlate'
    }) 

    return(
        <>
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
            >
                <Contact type="work" setModalIsOpen={setModalIsOpen} />
            </Modal>
            <HeaderComponent modalIsOpen={modalIsOpen}  />
            <HeroComponent data={hero} us={true} setModalIsOpen={setModalIsOpen} />
            { window.screen.width < 768 && <div style={{width: '100%', height: '150px'}} />}
            <p id="startup" style={{margin: 0}}></p>
            <SectionWithText data={sectionWithText} />
            {/* <VideoContainer>
                <div className="videoContainer">
                    <iframe 
                    src="https://www.youtube.com/embed/su01gJTrdKc" 
                    title="YouTube video player" 
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                    autoplay={1}
                    width="560" height="315"
                    ></iframe>
                </div>
            </VideoContainer> */}
            <H2>Conoce nuestra historia</H2>
            <Ul>
                <Div />
                <Li>3MIT nace en 2018 como una empresa de servicio de desarrollo de software a medida.</Li>
                <Li>Descubrimos la carencia de procesos estructurados y optimizados de nuestros primeros clientes.</Li>
                <Li>Por eso agregamos a nuestra propuesta: comenzar por acompañarlos en una primera fase de infraestructura base para ser ese punto de partida que les permitirá posteriormente desarrollar y escalar acorde a sus necesidades.</Li>
                <Li>En 2020, 3MIT toma a Odoo como producto insignia, siendo la solución a ese punto de partida que necesitaban todos nuestros clientes.</Li>
                <Li>Así, 3MIT se consolida como una empresa de Desarrollo de Software y Consultora de Negocios que acompaña a todo tipo de compañías a estructurarse e innovar a partir de la tecnología.</Li>
            </Ul>
            <Section data={sectionOne} background={colors.sectionBg} bold={true} title="Nuestros pilares" />
            <Carousel data={data} title="Testimonios" />
            <SectionWithButton data={sectionFour} background={colors.sectionBg} setModalIsOpen={setModalIsOpen} />
            <Footer landing="us" modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
        </>
    )
}