import styled from 'styled-components'
import { colors, size } from '../../styles/Global'

export const Div = styled.div`
    width: 700px;
    margin: 0 auto 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${size.mobileM}) {
        width: 70%;
    }
`

export const Img = styled.img`
    display: block;
    margin: 20px auto;
    @media (max-width: ${size.mobileM}) {
    }
`