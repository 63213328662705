import styled, { keyframes} from 'styled-components'
import { NavLink } from 'react-router-dom'
import { colors, size } from '../../styles/Global'

const fadeIn = keyframes`
 0% { opacity: 0 }
 100% { opacity: 1 }
`
const fadeOut = keyframes`
 0% { opacity: 1 }
 100% { opacity: 0 }
`

export const MenuContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: ${props => props.isOpen ? '0px' : '-100vw' };
    z-index: 200000;
    background-color: rgb(0 0 0 / 5%);
    transition: 0.25s;
`;
export const Navigation = styled(NavLink)`
    margin: 10px 0 10px 20px;
    /* color: ${colors.lightGray}; */
    text-decoration: none;
    font-size: 18px;
    font-weight: bolder;
`;
export const MenuContent = styled.div`
    /* background-color: #E9EFFB; */
    background-color: #fff;
    box-shadow: 0px 4px 24px rgb(0, 0, 0, 0.20);
    width: 70%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* border-bottom-right-radius: 50%; */
`;
export const Close = styled.div`
    margin: 20px 20px 20px auto
`;