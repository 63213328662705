import styled from 'styled-components'
import { colors, size } from '../../styles/Global'
import { Link } from 'react-router-dom'

export const ImplementationsContainer = styled.div`
    background-color: ${props => props.background ? props.background : 'white'};
    width: 80%;
    margin: 50px auto;
    padding: 50px 50px 100px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${size.mobileM}) {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 0;
        margin: 150px 0;
    }
`
export const Div = styled.div`
    width: 100%;
    margin: 20px 0;
    text-align: center;
`

export const ImplemetationsTitle = styled.p`
    font-size: 36px;
    font-weight: bold;
    color: ${colors.purpleText};
    @media (max-width: ${size.mobileM}) {
        text-align: center;
        width: 90%;
        margin: 0 auto;
    }
`
export const ImplemetationsText = styled.p`
    font-size: 16px;
    font-weight: normal;
    color: ${colors.purpleText};
    @media (max-width: ${size.mobileM}) {
        text-align: center;
        margin: 10px 20px;
    }
`
export const CardsContainer = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0 60px;
    @media (max-width: ${size.mobileM}) {
        flex-wrap: wrap;
        margin: 20px 0;
    }
`

// card 
export const CardContainer = styled.div`
    position: relative;
    border: 1px solid ${colors.border};
    width: 250px;
    background-color: ${colors.offWhite};
    border-radius: 16px;
    box-shadow: 2px 2px 24px rgb(0,0,0,0.2);
    @media (max-width: ${size.mobileM}) {
        margin: 20px 0;
        width: 450px;
    }
`
export const CardImageContainer = styled.div`
    /* width: 100%; */
    padding: 25px 0;
    border-radius: 16px;
    background-color: ${props => props.background ? props.background : 'white'};
`
export const CardImage = styled.img`
    display: block;
    margin: auto;
`
export const Title = styled.p`
    margin: 20px;
    font-size: 24px;
    font-weight: bold;
    color: ${colors.purpleText};
    @media (max-width: ${size.mobileM}) {
        font-size: 20px;
    }
`
export const Text = styled.p`
    margin: 20px 20px 60px 20px;
    font-size: 16px;
    font-weight: regular;
    color: ${colors.purpleText};
`
export const KnowMore = styled(Link)`
    position: absolute;
    bottom: 0;
    margin: auto auto 20px 20px;
    text-decoration: none;
    /* margin: auto; */
    font-size: 16px;
    font-weight: normal;
    color: ${colors.primaryIndigo};
`