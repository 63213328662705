import styled from 'styled-components'

export const colors = {
    lightGray: '#666666',
    border: '#F5F5F5',
    primaryIndigo: '#6C62D9',
    offWhite: "#fff",
    purpleText: '#22326E',
    sectionBg: '#F0F5F8',
    footer: '#0B0B26'
}

export const size = {
    mobileS: '320px',
    mobileSM: '375px',
    mobileMX: '425px',
    mobileM: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
  }

export const Hero = styled.div`
width: 100%;
height: 100vh;
background-image: url(${props => props.image});
background-size: contain;
background-repeat: no-repeat;
display: flex;
justify-content: center;
align-items: center;
`

export const HeroInfoCcontainer = styled.div`
max-width: 400px;
margin-right: 40%;
`
export const H1 = styled.h1`
margin: 0;
font-size: 48px;
color: #22326E;
`
export const H2 = styled.h1`
width: 100%;
text-align: center;
margin: 0;
font-size: 35px;
font-weight: 700;
color: #22326E;
@media (max-width: ${size.mobileM}) {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 25px;
    margin: 20px auto 50px;
    width: 80%;
}
`
export const P = styled.div`
margin: 20px 0;
font-size: 24px;
color: #22326E;
font-weight: 600;
`
export const PrimaryButton = styled.button`
color: ${colors.offWhite};
text-decoration: none;
font-size: 18px;
font-weight: bolder;
border: 3px solid ${colors.primaryIndigo};
padding: 12px 32px;
border-radius: 4px;
background: ${colors.primaryIndigo};
`
export const SecondaryButton = styled.button`
color: ${colors.primaryIndigo};
text-decoration: none;
font-size: 18px;
font-weight: bolder;
border: 3px solid ${colors.primaryIndigo};
padding: 12px 32px;
border-radius: 4px;
background: none;
`
export const CTA2 = styled.div`
width: 80%;
height: 180px;
margin: 50px auto;
padding: 80px 0;
background-color: ${colors.sectionBg};
border-radius: 16px;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
@media (max-width: ${size.mobileM}) {
    height: auto;
}
`